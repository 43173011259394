.TagPage > h1 {
  font-family: Montserrat, sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 1.7rem;
  margin-bottom: 2.4rem;  
  text-rendering: optimizeLegibility;
}

.TagPage > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.TagPage > ul > li {
  margin: 0;
  padding: 0;
}